import React, { useEffect } from "react"
import { Link } from "@StarberryUtils";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const NotFoundPage = () => {
    const [state, setState] = React.useState({
        showMenu: false
      })
    const handlerClick = () => {
        setState({ ...state, showMenu: !state.showMenu })
      }
  return (
    <React.Fragment>
        <div className={`${state.showMenu ? "open-search-block" : "area-guide-detail"}`}>
            <Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} /> 
            <div className="container">
                <div className="error-page-content pt-5">
                    <h1><span>Ooops!...</span></h1>
                    <h2>The page you’re after seems to be missing.</h2>
                    <p>This is maybe because it has been removed, we’ve changed it’s name or it is temporarily unavailable.</p>
                    <div class="exp-btns">
                    <Link to="/" class="btn btn-primary">Back to homepage</Link>
                    <Link to="/contact" class="btn btn-primary">Contact us</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </React.Fragment>
  )

}

export default NotFoundPage