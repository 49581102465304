import React, { useEffect } from "react"
import { Link } from "@StarberryUtils";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NotFound from "../components/404Page/404Page";

const NotFoundPage = () => {
  const [state, setState] = React.useState({
    showMenu: false
  })
  const [render, setRender] = React.useState(false)

  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }
  useEffect(() => {
    if (!render) {
      setRender(true)
    }

  })

  return (
    <React.Fragment>
      {render &&
        <NotFound />
      }

    </React.Fragment>
  )

}

export default NotFoundPage
